'use client'

import { Cell, Image, Text } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import useAbTest from 'hooks/useAbTest'
import { getTestId } from '_libs/utils/testId'
import { profileClickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { AbTestVariant } from 'constants/abtest'
import useSession from 'hooks/useSession'
import useAbTestExposeTracking from 'hooks/useAbTestExposeTracking'

type Props = {
  id: number
  name: string
  image: string
  alt?: string
  url: string
  urlProps?: JSX.IntrinsicElements['a'] & Record<`data-${string}`, unknown>
  /**
   * Adds data-testid attribute to children components.
   * When used, --owner and --owner-image suffixes applied accordingly.
   */
  testId?: string
}

const ItemBoxOwner = ({ id: userId, name, image, url, alt, urlProps, testId }: Props) => {
  const { screen } = useSession()

  const { track } = useTracking()
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q3' })?.variant === AbTestVariant.On
  const hideOwnerAbTest = useAbTest({
    abTestName: 'seller_profile_removal_from_item_box_v2',
  })
  const { trackExpose: trackOwnerView } = useAbTestExposeTracking({
    abTestName: 'seller_profile_removal_from_item_box_v2',
    shouldTrackOnce: true,
  })

  const hideOwner = buyerHoldoutAbTestEnabled && hideOwnerAbTest?.variant === AbTestVariant.On

  const handleOnClick = () => {
    track(
      profileClickEvent({
        target: ClickableElement.ItemBoxUserProfileLink,
        ownerId: userId.toString(),
        screen,
      }),
    )
  }

  const handleOwnerView = (inView: boolean) =>
    inView && buyerHoldoutAbTestEnabled && hideOwnerAbTest && trackOwnerView()

  if (hideOwner) return <InView as="div" onChange={handleOwnerView} />

  return (
    <InView data-testid={getTestId(testId, 'owner')} as="div" onChange={handleOwnerView}>
      <Cell
        styling={Cell.Styling.Narrow}
        prefix={
          <Image
            src={image}
            alt={alt}
            styling={Image.Styling.Circle}
            size={Image.Size.Regular}
            testId={getTestId(testId, 'owner-image')}
          />
        }
        url={url}
        urlProps={urlProps}
        onClick={handleOnClick}
      >
        <div className="u-flexbox">
          <Text
            text={name}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'owner-name')}
            as="p"
          />
        </div>
      </Cell>
    </InView>
  )
}

export default ItemBoxOwner
